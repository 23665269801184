/* eslint-disable */
'use client';
import { className, className2, css, css2, fonts, fonts2, } from './chunk-XBGIWYFG.js';
import { className as className4, css as css4, fonts as fonts4, } from './chunk-VHAJRWMR.js';
import { className as className3, css as css3, fonts as fonts3, } from './chunk-W6H3PCSD.js';
import { stdin_default, } from './chunk-HAOW4VH4.js';
import { Icon, } from './chunk-UV4LTKKZ.js';

// https :https://framerusercontent.com/modules/eIZhpWQLSmhgysJJumEh/vArVUubWW87icn6Zttpb/FF233TYmj.js
import { jsx as _jsx2, jsxs as _jsxs2, } from 'react/jsx-runtime';
import {
  addFonts as addFonts2,
  addPropertyControls as addPropertyControls2,
  ComponentViewportProvider as ComponentViewportProvider2,
  ControlType as ControlType2,
  cx as cx2,
  getFonts as getFonts2,
  getFontsFromSharedStyle as getFontsFromSharedStyle2,
  Image,
  RichText as RichText2,
  useComponentViewport as useComponentViewport2,
  useLocaleInfo as useLocaleInfo2,
  useVariantState as useVariantState2,
  withCSS as withCSS2,
  withFX as withFX2,
} from 'unframer';
import { LayoutGroup as LayoutGroup2, motion as motion2, MotionConfigContext as MotionConfigContext2, } from 'unframer';
import * as React2 from 'react';

// https :https://framerusercontent.com/modules/lSrPguuOrsW4P1B2lEy1/MJE2sX4BnWUCxn6ZpnVu/THPkjzinl.js
import { jsx as _jsx, jsxs as _jsxs, } from 'react/jsx-runtime';
import {
  addFonts,
  addPropertyControls,
  ComponentViewportProvider,
  ControlType,
  cx,
  getFonts,
  getFontsFromSharedStyle,
  RichText,
  useComponentViewport,
  useLocaleInfo,
  useVariantState,
  withCSS,
  withFX,
} from 'unframer';
import { LayoutGroup, motion, MotionConfigContext, } from 'unframer';
import * as React from 'react';

// https :https://framerusercontent.com/modules/1kgOPaSMvyliLSK49PCs/6SB4wnHvwwrEGryspEpL/XDdZznbn8.js
import { fontStore, } from 'unframer';
fontStore.loadFonts(['GF;Space Grotesk-700',],);
var fonts5 = [{
  family: 'Space Grotesk',
  source: 'google',
  style: 'normal',
  url: 'https://fonts.gstatic.com/s/spacegrotesk/v16/V8mQoQDjQSkFtoMM3T6r8E7mF71Q-gOoraIAEj4PVksmNsFjTDJK.woff2',
  weight: '700',
},];
var css5 = [
  '.framer-qthLV .framer-styles-preset-1ue4n2o:not(.rich-text-wrapper), .framer-qthLV .framer-styles-preset-1ue4n2o.rich-text-wrapper h2 { --framer-font-family: "Space Grotesk", "Space Grotesk Placeholder", sans-serif; --framer-font-size: 48px; --framer-font-style: normal; --framer-font-weight: 700; --framer-letter-spacing: -1.5px; --framer-line-height: 1.2em; --framer-paragraph-spacing: 0px; --framer-text-alignment: start; --framer-text-color: var(--token-b50174c0-f355-49d1-a882-48c9e51b5466, #ffffff); --framer-text-decoration: none; --framer-text-transform: none; }',
  '@media (max-width: 1439px) and (min-width: 992px) { .framer-qthLV .framer-styles-preset-1ue4n2o:not(.rich-text-wrapper), .framer-qthLV .framer-styles-preset-1ue4n2o.rich-text-wrapper h2 { --framer-font-family: "Space Grotesk", "Space Grotesk Placeholder", sans-serif; --framer-font-size: 45px; --framer-font-style: normal; --framer-font-weight: 700; --framer-letter-spacing: -1.5px; --framer-line-height: 1.2em; --framer-paragraph-spacing: 0px; --framer-text-alignment: start; --framer-text-color: var(--token-b50174c0-f355-49d1-a882-48c9e51b5466, #ffffff); --framer-text-decoration: none; --framer-text-transform: none; } }',
  '@media (max-width: 991px) and (min-width: 768px) { .framer-qthLV .framer-styles-preset-1ue4n2o:not(.rich-text-wrapper), .framer-qthLV .framer-styles-preset-1ue4n2o.rich-text-wrapper h2 { --framer-font-family: "Space Grotesk", "Space Grotesk Placeholder", sans-serif; --framer-font-size: 40px; --framer-font-style: normal; --framer-font-weight: 700; --framer-letter-spacing: -1.5px; --framer-line-height: 1.2em; --framer-paragraph-spacing: 0px; --framer-text-alignment: start; --framer-text-color: var(--token-b50174c0-f355-49d1-a882-48c9e51b5466, #ffffff); --framer-text-decoration: none; --framer-text-transform: none; } }',
  '@media (max-width: 767px) and (min-width: 0px) { .framer-qthLV .framer-styles-preset-1ue4n2o:not(.rich-text-wrapper), .framer-qthLV .framer-styles-preset-1ue4n2o.rich-text-wrapper h2 { --framer-font-family: "Space Grotesk", "Space Grotesk Placeholder", sans-serif; --framer-font-size: 36px; --framer-font-style: normal; --framer-font-weight: 700; --framer-letter-spacing: -1px; --framer-line-height: 1.2em; --framer-paragraph-spacing: 0px; --framer-text-alignment: start; --framer-text-color: var(--token-b50174c0-f355-49d1-a882-48c9e51b5466, #ffffff); --framer-text-decoration: none; --framer-text-transform: none; } }',
];
var className5 = 'framer-qthLV';

// https :https://framerusercontent.com/modules/lSrPguuOrsW4P1B2lEy1/MJE2sX4BnWUCxn6ZpnVu/THPkjzinl.js
var FeatherFonts = getFonts(Icon,);
var MotionDivWithFX = withFX(motion.div,);
var RichTextWithFX = withFX(RichText,);
var cycleOrder = ['xNkBnT7Cc', 'ldIilTyE_', 'qXdVD633r', 'zrydQfKFZ',];
var serializationHash = 'framer-eAda1';
var variantClassNames = {
  ldIilTyE_: 'framer-v-1tq21ma',
  qXdVD633r: 'framer-v-1qbynle',
  xNkBnT7Cc: 'framer-v-19ocl1v',
  zrydQfKFZ: 'framer-v-o4rghs',
};
function addPropertyOverrides(overrides, ...variants) {
  const nextOverrides = {};
  variants === null || variants === void 0
    ? void 0
    : variants.forEach((variant,) => variant && Object.assign(nextOverrides, overrides[variant],));
  return nextOverrides;
}
var transition1 = { damping: 60, delay: 0, mass: 1, stiffness: 500, type: 'spring', };
var animation = { opacity: 0, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, transformPerspective: 1200, x: 0, y: 50, };
var transition2 = { damping: 40, delay: 0, mass: 1, stiffness: 400, type: 'spring', };
var transition3 = { damping: 40, delay: 0.2, mass: 1, stiffness: 400, type: 'spring', };
var transition4 = { damping: 40, delay: 0.4, mass: 1, stiffness: 400, type: 'spring', };
var Transition = ({ value, children, },) => {
  const config = React.useContext(MotionConfigContext,);
  const transition = value !== null && value !== void 0 ? value : config.transition;
  const contextValue = React.useMemo(() => ({ ...config, transition, }), [JSON.stringify(transition,),],);
  return /* @__PURE__ */ _jsx(MotionConfigContext.Provider, { value: contextValue, children, },);
};
var Variants = motion(React.Fragment,);
var humanReadableVariantMap = {
  'H1 Center Align': 'ldIilTyE_',
  'H1 Left Align': 'xNkBnT7Cc',
  'H2 Center Align': 'zrydQfKFZ',
  'H2 Left Align': 'qXdVD633r',
};
var getProps = ({ height, iconVisible, id, tagline, text, textVisible, title, width, ...props },) => {
  var _ref, _ref1, _ref2, _ref3, _humanReadableVariantMap_props_variant, _ref4, _ref5;
  return {
    ...props,
    cASqLqJU3: (_ref = textVisible !== null && textVisible !== void 0 ? textVisible : props.cASqLqJU3) !== null && _ref !== void 0
      ? _ref
      : true,
    CUE0ztma1: (_ref1 = title !== null && title !== void 0 ? title : props.CUE0ztma1) !== null && _ref1 !== void 0
      ? _ref1
      : 'Decentralizing Systems and Building Blockchain & Web3',
    MtTJpx7dZ: (_ref2 = tagline !== null && tagline !== void 0 ? tagline : props.MtTJpx7dZ) !== null && _ref2 !== void 0
      ? _ref2
      : 'Systems and Building Web3',
    nG9I1sRlG: (_ref3 = iconVisible !== null && iconVisible !== void 0 ? iconVisible : props.nG9I1sRlG) !== null && _ref3 !== void 0
      ? _ref3
      : true,
    variant:
      (_ref4 =
            (_humanReadableVariantMap_props_variant = humanReadableVariantMap[props.variant]) !== null &&
              _humanReadableVariantMap_props_variant !== void 0
              ? _humanReadableVariantMap_props_variant
              : props.variant) !== null && _ref4 !== void 0
        ? _ref4
        : 'xNkBnT7Cc',
    zzNuawToN: (_ref5 = text !== null && text !== void 0 ? text : props.zzNuawToN) !== null && _ref5 !== void 0
      ? _ref5
      : 'Secure, transparent transfer of data and value without centralized control. Building blocks for Web3 and metaverse',
  };
};
var createLayoutDependency = (props, variants,) => {
  if (props.layoutDependency) return variants.join('-',) + props.layoutDependency;
  return variants.join('-',);
};
var Component = /* @__PURE__ */ React.forwardRef(function (props, ref,) {
  const { activeLocale, setLocale, } = useLocaleInfo();
  const { style, className: className6, layoutId, variant, MtTJpx7dZ, CUE0ztma1, zzNuawToN, cASqLqJU3, nG9I1sRlG, ...restProps } = getProps(
    props,
  );
  const { baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants, } = useVariantState({
    cycleOrder,
    defaultVariant: 'xNkBnT7Cc',
    variant,
    variantClassNames,
  },);
  const layoutDependency = createLayoutDependency(props, variants,);
  const ref1 = React.useRef(null,);
  const defaultLayoutId = React.useId();
  const sharedStyleClassNames = [className3, className, className5, className2,];
  const componentViewport = useComponentViewport();
  return /* @__PURE__ */ _jsx(LayoutGroup, {
    id: layoutId !== null && layoutId !== void 0 ? layoutId : defaultLayoutId,
    children: /* @__PURE__ */ _jsx(Variants, {
      animate: variants,
      initial: false,
      children: /* @__PURE__ */ _jsx(Transition, {
        value: transition1,
        children: /* @__PURE__ */ _jsxs(motion.div, {
          ...restProps,
          ...gestureHandlers,
          className: cx(serializationHash, ...sharedStyleClassNames, 'framer-19ocl1v', className6, classNames,),
          'data-framer-name': 'H1 Left Align',
          layoutDependency,
          layoutId: 'xNkBnT7Cc',
          ref: ref !== null && ref !== void 0 ? ref : ref1,
          style: { ...style, },
          ...addPropertyOverrides(
            {
              ldIilTyE_: { 'data-framer-name': 'H1 Center Align', },
              qXdVD633r: { 'data-framer-name': 'H2 Left Align', },
              zrydQfKFZ: { 'data-framer-name': 'H2 Center Align', },
            },
            baseVariant,
            gestureVariant,
          ),
          children: [
            /* @__PURE__ */ _jsx(MotionDivWithFX, {
              __framer__animate: { transition: transition2, },
              __framer__animateOnce: true,
              __framer__enter: animation,
              __framer__styleAppearEffectEnabled: true,
              __framer__threshold: 0.5,
              __perspectiveFX: false,
              __smartComponentFX: true,
              __targetOpacity: 1,
              className: 'framer-1yamqg4',
              'data-framer-name': 'Tagline',
              layoutDependency,
              layoutId: 'FgHb6hcD4',
              style: {
                background:
                  'linear-gradient(90deg, rgb(193, 255, 86) 0%, rgb(100, 231, 78) 26.126126126126124%, rgb(167, 68, 202) 48.1981981981982%, rgb(60, 177, 170) 73.87387387387388%, rgb(193, 255, 86) 100%)',
                borderBottomLeftRadius: 24,
                borderBottomRightRadius: 24,
                borderTopLeftRadius: 24,
                borderTopRightRadius: 24,
                transformPerspective: 1200,
              },
              children: /* @__PURE__ */ _jsxs(motion.div, {
                className: 'framer-19eh289',
                'data-framer-name': 'Tagline Content',
                layoutDependency,
                layoutId: 'Pl15YSypg',
                style: {
                  backgroundColor: 'rgb(18, 22, 11)',
                  borderBottomLeftRadius: 24,
                  borderBottomRightRadius: 24,
                  borderTopLeftRadius: 24,
                  borderTopRightRadius: 24,
                },
                children: [
                  /* @__PURE__ */ _jsx(RichText, {
                    __fromCanvasComponent: true,
                    children: /* @__PURE__ */ _jsx(React.Fragment, {
                      children: /* @__PURE__ */ _jsx(motion.p, {
                        className: 'framer-styles-preset-17ww93c',
                        'data-styles-preset': 'uMdppUE31',
                        style: {
                          '--framer-text-color':
                            'var(--extracted-r6o4lv, var(--token-a05655a1-e6dd-4d69-a82a-f5054b601f79, rgb(193, 255, 86)))',
                        },
                        children: 'Systems and Building Web3',
                      },),
                    },),
                    className: 'framer-1d6oxq0',
                    'data-framer-name': 'Systems and Building Web3',
                    fonts: ['Inter',],
                    layoutDependency,
                    layoutId: 'sZl3PsRfD',
                    style: {
                      '--extracted-r6o4lv': 'var(--token-a05655a1-e6dd-4d69-a82a-f5054b601f79, rgb(193, 255, 86))',
                      '--framer-paragraph-spacing': '0px',
                    },
                    text: MtTJpx7dZ,
                    verticalAlignment: 'top',
                    withExternalLayout: true,
                  },),
                  nG9I1sRlG && /* @__PURE__ */ _jsx(motion.div, {
                    className: 'framer-c32kkv',
                    'data-framer-name': 'chevron-right',
                    layoutDependency,
                    layoutId: 'bJmV6nSFv',
                    children: /* @__PURE__ */ _jsx(ComponentViewportProvider, {
                      children: /* @__PURE__ */ _jsx(motion.div, {
                        className: 'framer-1d19sdp-container',
                        layoutDependency,
                        layoutId: 'EeQA1D4zF-container',
                        children: /* @__PURE__ */ _jsx(Icon, {
                          color: 'var(--token-a05655a1-e6dd-4d69-a82a-f5054b601f79, rgb(193, 255, 86))',
                          height: '100%',
                          iconSearch: 'Home',
                          iconSelection: 'chevron-right',
                          id: 'EeQA1D4zF',
                          layoutId: 'EeQA1D4zF',
                          mirrored: false,
                          selectByList: true,
                          style: { height: '100%', width: '100%', },
                          width: '100%',
                        },),
                      },),
                    },),
                  },),
                ],
              },),
            },),
            /* @__PURE__ */ _jsxs(motion.div, {
              className: 'framer-1fiuvmr',
              'data-framer-name': 'title',
              layoutDependency,
              layoutId: 'UKgn38cNJ',
              children: [
                /* @__PURE__ */ _jsx(RichTextWithFX, {
                  __framer__animate: { transition: transition3, },
                  __framer__animateOnce: true,
                  __framer__enter: animation,
                  __framer__styleAppearEffectEnabled: true,
                  __framer__threshold: 0,
                  __fromCanvasComponent: true,
                  __perspectiveFX: false,
                  __smartComponentFX: true,
                  __targetOpacity: 1,
                  children: /* @__PURE__ */ _jsx(React.Fragment, {
                    children: /* @__PURE__ */ _jsx(motion.h1, {
                      className: 'framer-styles-preset-103wjsp',
                      'data-styles-preset': 'fCZWqKM9S',
                      style: {
                        '--framer-text-color':
                          'var(--extracted-gdpscs, var(--token-b50174c0-f355-49d1-a882-48c9e51b5466, rgb(255, 255, 255)))',
                      },
                      children: 'Decentralizing Systems and Building Blockchain & Web3',
                    },),
                  },),
                  className: 'framer-1bh5qzz',
                  'data-framer-name': 'Decentralizing Systems and Building Blockchain & Web3',
                  fonts: ['Inter',],
                  layoutDependency,
                  layoutId: 'CA1bDZ6F1',
                  style: {
                    '--extracted-gdpscs': 'var(--token-b50174c0-f355-49d1-a882-48c9e51b5466, rgb(255, 255, 255))',
                    '--framer-paragraph-spacing': '0px',
                    transformPerspective: 1200,
                  },
                  text: CUE0ztma1,
                  variants: {
                    qXdVD633r: { '--extracted-1of0zx5': 'var(--token-b50174c0-f355-49d1-a882-48c9e51b5466, rgb(255, 255, 255))', },
                    zrydQfKFZ: { '--extracted-1of0zx5': 'var(--token-b50174c0-f355-49d1-a882-48c9e51b5466, rgb(255, 255, 255))', },
                  },
                  verticalAlignment: 'top',
                  withExternalLayout: true,
                  ...addPropertyOverrides(
                    {
                      ldIilTyE_: {
                        children: /* @__PURE__ */ _jsx(React.Fragment, {
                          children: /* @__PURE__ */ _jsx(motion.h1, {
                            className: 'framer-styles-preset-103wjsp',
                            'data-styles-preset': 'fCZWqKM9S',
                            style: {
                              '--framer-text-alignment': 'center',
                              '--framer-text-color':
                                'var(--extracted-gdpscs, var(--token-b50174c0-f355-49d1-a882-48c9e51b5466, rgb(255, 255, 255)))',
                            },
                            children: 'Decentralizing Systems and Building Blockchain & Web3',
                          },),
                        },),
                      },
                      qXdVD633r: {
                        children: /* @__PURE__ */ _jsx(React.Fragment, {
                          children: /* @__PURE__ */ _jsx(motion.h2, {
                            className: 'framer-styles-preset-1ue4n2o',
                            'data-styles-preset': 'XDdZznbn8',
                            style: {
                              '--framer-text-color':
                                'var(--extracted-1of0zx5, var(--token-b50174c0-f355-49d1-a882-48c9e51b5466, rgb(255, 255, 255)))',
                            },
                            children: 'Decentralizing Systems and Building Blockchain & Web3',
                          },),
                        },),
                      },
                      zrydQfKFZ: {
                        children: /* @__PURE__ */ _jsx(React.Fragment, {
                          children: /* @__PURE__ */ _jsx(motion.h2, {
                            className: 'framer-styles-preset-1ue4n2o',
                            'data-styles-preset': 'XDdZznbn8',
                            style: {
                              '--framer-text-alignment': 'center',
                              '--framer-text-color':
                                'var(--extracted-1of0zx5, var(--token-b50174c0-f355-49d1-a882-48c9e51b5466, rgb(255, 255, 255)))',
                            },
                            children: 'Decentralizing Systems and Building Blockchain & Web3',
                          },),
                        },),
                      },
                    },
                    baseVariant,
                    gestureVariant,
                  ),
                },),
                cASqLqJU3 && /* @__PURE__ */ _jsx(RichTextWithFX, {
                  __framer__animate: { transition: transition4, },
                  __framer__animateOnce: true,
                  __framer__enter: animation,
                  __framer__styleAppearEffectEnabled: true,
                  __framer__threshold: 0,
                  __fromCanvasComponent: true,
                  __perspectiveFX: false,
                  __smartComponentFX: true,
                  __targetOpacity: 0.7,
                  children: /* @__PURE__ */ _jsx(React.Fragment, {
                    children: /* @__PURE__ */ _jsx(motion.p, {
                      className: 'framer-styles-preset-1kx6ka7',
                      'data-styles-preset': 's9en4OXwY',
                      style: {
                        '--framer-text-color':
                          'var(--extracted-r6o4lv, var(--token-b50174c0-f355-49d1-a882-48c9e51b5466, rgb(255, 255, 255)))',
                      },
                      children:
                        'Secure, transparent transfer of data and value without centralized control. Building blocks for Web3 and metaverse',
                    },),
                  },),
                  className: 'framer-1goerhh',
                  'data-framer-name':
                    'Secure, transparent transfer of data and value without centralized control. Building blocks for Web3 and metaverse',
                  fonts: ['Inter',],
                  layoutDependency,
                  layoutId: 'wT_QGpOTc',
                  style: {
                    '--extracted-r6o4lv': 'var(--token-b50174c0-f355-49d1-a882-48c9e51b5466, rgb(255, 255, 255))',
                    '--framer-paragraph-spacing': '0px',
                    opacity: 0.7,
                    transformPerspective: 1200,
                  },
                  text: zzNuawToN,
                  verticalAlignment: 'top',
                  withExternalLayout: true,
                  ...addPropertyOverrides(
                    {
                      ldIilTyE_: {
                        children: /* @__PURE__ */ _jsx(React.Fragment, {
                          children: /* @__PURE__ */ _jsx(motion.p, {
                            className: 'framer-styles-preset-1kx6ka7',
                            'data-styles-preset': 's9en4OXwY',
                            style: {
                              '--framer-text-alignment': 'center',
                              '--framer-text-color':
                                'var(--extracted-r6o4lv, var(--token-b50174c0-f355-49d1-a882-48c9e51b5466, rgb(255, 255, 255)))',
                            },
                            children:
                              'Secure, transparent transfer of data and value without centralized control. Building blocks for Web3 and metaverse',
                          },),
                        },),
                      },
                      zrydQfKFZ: {
                        children: /* @__PURE__ */ _jsx(React.Fragment, {
                          children: /* @__PURE__ */ _jsx(motion.p, {
                            className: 'framer-styles-preset-1kx6ka7',
                            'data-styles-preset': 's9en4OXwY',
                            style: {
                              '--framer-text-alignment': 'center',
                              '--framer-text-color':
                                'var(--extracted-r6o4lv, var(--token-b50174c0-f355-49d1-a882-48c9e51b5466, rgb(255, 255, 255)))',
                            },
                            children:
                              'Secure, transparent transfer of data and value without centralized control. Building blocks for Web3 and metaverse',
                          },),
                        },),
                      },
                    },
                    baseVariant,
                    gestureVariant,
                  ),
                },),
              ],
            },),
          ],
        },),
      },),
    },),
  },);
},);
var css6 = [
  '@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }',
  '.framer-eAda1.framer-eh9olz, .framer-eAda1 .framer-eh9olz { display: block; }',
  '.framer-eAda1.framer-19ocl1v { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 14px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 601px; }',
  '.framer-eAda1 .framer-1yamqg4 { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 37px; justify-content: center; overflow: hidden; padding: 1px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }',
  '.framer-eAda1 .framer-19eh289 { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 4px; height: 35px; justify-content: center; overflow: visible; padding: 4px 16px 6px 16px; position: relative; width: min-content; }',
  '.framer-eAda1 .framer-1d6oxq0 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }',
  '.framer-eAda1 .framer-c32kkv { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 16px); overflow: hidden; position: relative; width: 16px; }',
  '.framer-eAda1 .framer-1d19sdp-container { bottom: 2px; flex: none; left: 5px; position: absolute; right: 3px; top: 2px; }',
  '.framer-eAda1 .framer-1fiuvmr { align-content: flex-start; align-items: flex-start; display: flex; flex: none; flex-direction: column; flex-wrap: nowrap; gap: 20px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px; position: relative; width: 100%; }',
  '.framer-eAda1 .framer-1bh5qzz, .framer-eAda1 .framer-1goerhh { flex: none; height: auto; position: relative; white-space: pre-wrap; width: 100%; word-break: break-word; word-wrap: break-word; }',
  '@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-eAda1.framer-19ocl1v, .framer-eAda1 .framer-1yamqg4, .framer-eAda1 .framer-19eh289, .framer-eAda1 .framer-1fiuvmr { gap: 0px; } .framer-eAda1.framer-19ocl1v > * { margin: 0px; margin-bottom: calc(14px / 2); margin-top: calc(14px / 2); } .framer-eAda1.framer-19ocl1v > :first-child, .framer-eAda1 .framer-1fiuvmr > :first-child { margin-top: 0px; } .framer-eAda1.framer-19ocl1v > :last-child, .framer-eAda1 .framer-1fiuvmr > :last-child { margin-bottom: 0px; } .framer-eAda1 .framer-1yamqg4 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-eAda1 .framer-1yamqg4 > :first-child, .framer-eAda1 .framer-19eh289 > :first-child { margin-left: 0px; } .framer-eAda1 .framer-1yamqg4 > :last-child, .framer-eAda1 .framer-19eh289 > :last-child { margin-right: 0px; } .framer-eAda1 .framer-19eh289 > * { margin: 0px; margin-left: calc(4px / 2); margin-right: calc(4px / 2); } .framer-eAda1 .framer-1fiuvmr > * { margin: 0px; margin-bottom: calc(20px / 2); margin-top: calc(20px / 2); } }',
  '.framer-eAda1.framer-v-1tq21ma.framer-19ocl1v, .framer-eAda1.framer-v-1tq21ma .framer-1fiuvmr, .framer-eAda1.framer-v-o4rghs.framer-19ocl1v, .framer-eAda1.framer-v-o4rghs .framer-1fiuvmr { align-content: center; align-items: center; }',
  ...css3,
  ...css,
  ...css5,
  ...css2,
];
var FramerTHPkjzinl = withCSS(Component, css6, 'framer-eAda1',);
var stdin_default2 = FramerTHPkjzinl;
FramerTHPkjzinl.displayName = 'Section Title';
FramerTHPkjzinl.defaultProps = { height: 339, width: 601, };
addPropertyControls(FramerTHPkjzinl, {
  variant: {
    options: ['xNkBnT7Cc', 'ldIilTyE_', 'qXdVD633r', 'zrydQfKFZ',],
    optionTitles: ['H1 Left Align', 'H1 Center Align', 'H2 Left Align', 'H2 Center Align',],
    title: 'Variant',
    type: ControlType.Enum,
  },
  MtTJpx7dZ: { defaultValue: 'Systems and Building Web3', displayTextArea: false, title: 'Tagline', type: ControlType.String, },
  CUE0ztma1: {
    defaultValue: 'Decentralizing Systems and Building Blockchain & Web3',
    displayTextArea: false,
    title: 'Title',
    type: ControlType.String,
  },
  zzNuawToN: {
    defaultValue: 'Secure, transparent transfer of data and value without centralized control. Building blocks for Web3 and metaverse',
    displayTextArea: false,
    title: 'Text',
    type: ControlType.String,
  },
  cASqLqJU3: { defaultValue: true, title: 'Text Visible', type: ControlType.Boolean, },
  nG9I1sRlG: { defaultValue: true, title: 'Icon Visible', type: ControlType.Boolean, },
},);
addFonts(FramerTHPkjzinl, [
  {
    explicitInter: true,
    fonts: [{
      family: 'Inter',
      source: 'framer',
      style: 'normal',
      unicodeRange: 'U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F',
      url: 'https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2',
      weight: '400',
    }, {
      family: 'Inter',
      source: 'framer',
      style: 'normal',
      unicodeRange: 'U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116',
      url: 'https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2',
      weight: '400',
    }, {
      family: 'Inter',
      source: 'framer',
      style: 'normal',
      unicodeRange: 'U+1F00-1FFF',
      url: 'https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2',
      weight: '400',
    }, {
      family: 'Inter',
      source: 'framer',
      style: 'normal',
      unicodeRange: 'U+0370-03FF',
      url: 'https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2',
      weight: '400',
    }, {
      family: 'Inter',
      source: 'framer',
      style: 'normal',
      unicodeRange: 'U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF',
      url: 'https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2',
      weight: '400',
    }, {
      family: 'Inter',
      source: 'framer',
      style: 'normal',
      unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
      url: 'https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2',
      weight: '400',
    }, {
      family: 'Inter',
      source: 'framer',
      style: 'normal',
      unicodeRange: 'U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB',
      url: 'https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2',
      weight: '400',
    },],
  },
  ...FeatherFonts,
  ...getFontsFromSharedStyle(fonts3,),
  ...getFontsFromSharedStyle(fonts,),
  ...getFontsFromSharedStyle(fonts5,),
  ...getFontsFromSharedStyle(fonts2,),
], { supportsExplicitInterCodegen: true, },);

// https :https://framerusercontent.com/modules/eIZhpWQLSmhgysJJumEh/vArVUubWW87icn6Zttpb/FF233TYmj.js
var SectionTitleFonts = getFonts2(stdin_default2,);
var ButtonFonts = getFonts2(stdin_default,);
var MotionDivWithFX2 = withFX2(motion2.div,);
var ImageWithFX = withFX2(Image,);
var cycleOrder2 = ['FZPxxJjOq', 'OStzbM18C', 'RuodeXOmu', 'yVtgPmqxI',];
var serializationHash2 = 'framer-ZLRxz';
var variantClassNames2 = {
  FZPxxJjOq: 'framer-v-1usrhsu',
  OStzbM18C: 'framer-v-sitpyi',
  RuodeXOmu: 'framer-v-1ybeht9',
  yVtgPmqxI: 'framer-v-7efl8s',
};
function addPropertyOverrides2(overrides, ...variants) {
  const nextOverrides = {};
  variants === null || variants === void 0
    ? void 0
    : variants.forEach((variant,) => variant && Object.assign(nextOverrides, overrides[variant],));
  return nextOverrides;
}
var transition12 = { damping: 60, delay: 0, mass: 1, stiffness: 500, type: 'spring', };
var animation2 = { opacity: 0, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, transformPerspective: 1200, x: 0, y: 50, };
var transition22 = { damping: 40, delay: 0.4, mass: 1, stiffness: 400, type: 'spring', };
var animation1 = { opacity: 0, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, transformPerspective: 1200, x: 0, y: 0, };
var transition32 = { damping: 30, delay: 0.3, mass: 1, stiffness: 400, type: 'spring', };
var transition42 = { damping: 30, delay: 0.5, mass: 1, stiffness: 400, type: 'spring', };
var Transition2 = ({ value, children, },) => {
  const config = React2.useContext(MotionConfigContext2,);
  const transition = value !== null && value !== void 0 ? value : config.transition;
  const contextValue = React2.useMemo(() => ({ ...config, transition, }), [JSON.stringify(transition,),],);
  return /* @__PURE__ */ _jsx2(MotionConfigContext2.Provider, { value: contextValue, children, },);
};
var Variants2 = motion2(React2.Fragment,);
var humanReadableVariantMap2 = { Desktop: 'FZPxxJjOq', Laptop: 'OStzbM18C', Mobile: 'yVtgPmqxI', Tablet: 'RuodeXOmu', };
var getProps2 = ({ crispUrl, height, id, width, ...props },) => {
  var _humanReadableVariantMap_props_variant, _ref;
  return {
    ...props,
    Dm4Edjd64: crispUrl !== null && crispUrl !== void 0 ? crispUrl : props.Dm4Edjd64,
    variant:
      (_ref =
            (_humanReadableVariantMap_props_variant = humanReadableVariantMap2[props.variant]) !== null &&
              _humanReadableVariantMap_props_variant !== void 0
              ? _humanReadableVariantMap_props_variant
              : props.variant) !== null && _ref !== void 0
        ? _ref
        : 'FZPxxJjOq',
  };
};
var createLayoutDependency2 = (props, variants,) => {
  if (props.layoutDependency) return variants.join('-',) + props.layoutDependency;
  return variants.join('-',);
};
var Component2 = /* @__PURE__ */ React2.forwardRef(function (props, ref,) {
  const { activeLocale, setLocale, } = useLocaleInfo2();
  const { style, className: className6, layoutId, variant, Dm4Edjd64, ...restProps } = getProps2(props,);
  const { baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants, } = useVariantState2({
    cycleOrder: cycleOrder2,
    defaultVariant: 'FZPxxJjOq',
    variant,
    variantClassNames: variantClassNames2,
  },);
  const layoutDependency = createLayoutDependency2(props, variants,);
  const ref1 = React2.useRef(null,);
  const defaultLayoutId = React2.useId();
  const sharedStyleClassNames = [className4,];
  const componentViewport = useComponentViewport2();
  return /* @__PURE__ */ _jsx2(LayoutGroup2, {
    id: layoutId !== null && layoutId !== void 0 ? layoutId : defaultLayoutId,
    children: /* @__PURE__ */ _jsx2(Variants2, {
      animate: variants,
      initial: false,
      children: /* @__PURE__ */ _jsx2(Transition2, {
        value: transition12,
        children: /* @__PURE__ */ _jsxs2(motion2.div, {
          ...restProps,
          ...gestureHandlers,
          className: cx2(serializationHash2, ...sharedStyleClassNames, 'framer-1usrhsu', className6, classNames,),
          'data-framer-name': 'Desktop',
          layoutDependency,
          layoutId: 'FZPxxJjOq',
          ref: ref !== null && ref !== void 0 ? ref : ref1,
          style: { backgroundColor: 'var(--token-ff9d48bd-ec1e-45fc-b308-fcfb34b4089f, rgb(8, 8, 7))', ...style, },
          ...addPropertyOverrides2(
            {
              OStzbM18C: { 'data-framer-name': 'Laptop', },
              RuodeXOmu: { 'data-framer-name': 'Tablet', },
              yVtgPmqxI: { 'data-framer-name': 'Mobile', },
            },
            baseVariant,
            gestureVariant,
          ),
          children: [
            /* @__PURE__ */ _jsx2(ComponentViewportProvider2, {
              width: `calc(${
                (componentViewport === null || componentViewport === void 0 ? void 0 : componentViewport.width) || '100vw'
              } * 0.56)`,
              ...addPropertyOverrides2(
                {
                  OStzbM18C: {
                    width: `calc(${
                      (componentViewport === null || componentViewport === void 0 ? void 0 : componentViewport.width) || '100vw'
                    } * 0.53)`,
                  },
                  RuodeXOmu: {
                    width: `calc(${
                      (componentViewport === null || componentViewport === void 0 ? void 0 : componentViewport.width) || '100vw'
                    } * 0.57)`,
                  },
                  yVtgPmqxI: {
                    width: (componentViewport === null || componentViewport === void 0 ? void 0 : componentViewport.width) || '100vw',
                  },
                },
                baseVariant,
                gestureVariant,
              ),
              children: /* @__PURE__ */ _jsx2(motion2.div, {
                className: 'framer-1a199pe-container',
                layoutDependency,
                layoutId: 'WrsYPAnA5-container',
                children: /* @__PURE__ */ _jsx2(stdin_default2, {
                  cASqLqJU3: false,
                  CUE0ztma1: 'Use Crisp in Raycast for only  $19/month',
                  height: '100%',
                  id: 'WrsYPAnA5',
                  layoutId: 'WrsYPAnA5',
                  MtTJpx7dZ: 'Get Started',
                  nG9I1sRlG: false,
                  style: { width: '100%', },
                  variant: 'ldIilTyE_',
                  width: '100%',
                  zzNuawToN:
                    'Secure, transparent transfer of data and value without centralized control. Building blocks for Web3 and metaverse',
                },),
              },),
            },),
            /* @__PURE__ */ _jsx2(RichText2, {
              __fromCanvasComponent: true,
              children: /* @__PURE__ */ _jsx2(React2.Fragment, {
                children: /* @__PURE__ */ _jsx2(motion2.p, {
                  className: 'framer-styles-preset-15m69fh',
                  'data-styles-preset': 'DrH7EbF3F',
                  children: 'Payment is per site, unlimited operators',
                },),
              },),
              className: 'framer-10e2ga6',
              fonts: ['Inter',],
              layoutDependency,
              layoutId: 'GG3fLUy4C',
              style: { '--framer-link-text-color': 'rgb(0, 153, 255)', '--framer-link-text-decoration': 'underline', },
              verticalAlignment: 'top',
              withExternalLayout: true,
            },),
            /* @__PURE__ */ _jsx2(ComponentViewportProvider2, {
              children: /* @__PURE__ */ _jsx2(MotionDivWithFX2, {
                __framer__animate: { transition: transition22, },
                __framer__animateOnce: true,
                __framer__enter: animation2,
                __framer__styleAppearEffectEnabled: true,
                __framer__threshold: 0.5,
                __perspectiveFX: false,
                __smartComponentFX: true,
                __targetOpacity: 1,
                className: 'framer-yhwl67-container',
                layoutDependency,
                layoutId: 'WHz9rXbAH-container',
                style: { transformPerspective: 1200, },
                children: /* @__PURE__ */ _jsx2(stdin_default, {
                  c0gn5OQjt: 'Install Crisp Plugin',
                  FDhu5wZJE: Dm4Edjd64,
                  height: '100%',
                  id: 'WHz9rXbAH',
                  layoutId: 'WHz9rXbAH',
                  qyxXbxBX2: true,
                  variant: 'HpACQO_27',
                  width: '100%',
                },),
              },),
            },),
            /* @__PURE__ */ _jsx2(ImageWithFX, {
              __framer__animate: { transition: transition32, },
              __framer__animateOnce: true,
              __framer__enter: animation1,
              __framer__styleAppearEffectEnabled: true,
              __framer__threshold: 0.5,
              __perspectiveFX: false,
              __smartComponentFX: true,
              __targetOpacity: 1,
              background: {
                alt: '',
                fit: 'fill',
                intrinsicHeight: 243,
                intrinsicWidth: 179,
                pixelHeight: 243,
                pixelWidth: 179,
                src: 'https://framerusercontent.com/images/qxQZwttXswm8z64MAq3dvPKpo.svg',
              },
              className: 'framer-15iblgi',
              'data-framer-name': 'Left Image',
              layoutDependency,
              layoutId: 'GfE4HEQ02',
              style: { transformPerspective: 1200, },
              ...addPropertyOverrides2(
                {
                  OStzbM18C: {
                    background: {
                      alt: '',
                      fit: 'fit',
                      intrinsicHeight: 243,
                      intrinsicWidth: 179,
                      pixelHeight: 243,
                      pixelWidth: 179,
                      positionX: 'center',
                      positionY: 'center',
                      src: 'https://framerusercontent.com/images/qxQZwttXswm8z64MAq3dvPKpo.svg',
                    },
                  },
                  RuodeXOmu: {
                    background: {
                      alt: '',
                      fit: 'fit',
                      intrinsicHeight: 243,
                      intrinsicWidth: 179,
                      pixelHeight: 243,
                      pixelWidth: 179,
                      positionX: 'center',
                      positionY: 'center',
                      src: 'https://framerusercontent.com/images/qxQZwttXswm8z64MAq3dvPKpo.svg',
                    },
                  },
                  yVtgPmqxI: {
                    background: {
                      alt: '',
                      fit: 'fit',
                      intrinsicHeight: 243,
                      intrinsicWidth: 179,
                      pixelHeight: 243,
                      pixelWidth: 179,
                      positionX: 'center',
                      positionY: 'center',
                      src: 'https://framerusercontent.com/images/qxQZwttXswm8z64MAq3dvPKpo.svg',
                    },
                  },
                },
                baseVariant,
                gestureVariant,
              ),
            },),
            /* @__PURE__ */ _jsx2(ImageWithFX, {
              __framer__animate: { transition: transition42, },
              __framer__animateOnce: true,
              __framer__enter: animation1,
              __framer__styleAppearEffectEnabled: true,
              __framer__threshold: 0.5,
              __perspectiveFX: false,
              __smartComponentFX: true,
              __targetOpacity: 1,
              background: {
                alt: '',
                fit: 'fill',
                intrinsicHeight: 243,
                intrinsicWidth: 179,
                pixelHeight: 243,
                pixelWidth: 179,
                src: 'https://framerusercontent.com/images/qxQZwttXswm8z64MAq3dvPKpo.svg',
              },
              className: 'framer-qtiat9',
              'data-framer-name': 'Right Image',
              layoutDependency,
              layoutId: 'y3RpJ0Mam',
              style: { transformPerspective: 1200, },
              ...addPropertyOverrides2(
                {
                  OStzbM18C: {
                    background: {
                      alt: '',
                      fit: 'fit',
                      intrinsicHeight: 243,
                      intrinsicWidth: 179,
                      pixelHeight: 243,
                      pixelWidth: 179,
                      positionX: 'center',
                      positionY: 'center',
                      src: 'https://framerusercontent.com/images/qxQZwttXswm8z64MAq3dvPKpo.svg',
                    },
                  },
                  RuodeXOmu: {
                    background: {
                      alt: '',
                      fit: 'fit',
                      intrinsicHeight: 243,
                      intrinsicWidth: 179,
                      pixelHeight: 243,
                      pixelWidth: 179,
                      positionX: 'center',
                      positionY: 'center',
                      src: 'https://framerusercontent.com/images/qxQZwttXswm8z64MAq3dvPKpo.svg',
                    },
                  },
                  yVtgPmqxI: {
                    background: {
                      alt: '',
                      fit: 'fit',
                      intrinsicHeight: 243,
                      intrinsicWidth: 179,
                      pixelHeight: 243,
                      pixelWidth: 179,
                      positionX: 'center',
                      positionY: 'center',
                      src: 'https://framerusercontent.com/images/qxQZwttXswm8z64MAq3dvPKpo.svg',
                    },
                  },
                },
                baseVariant,
                gestureVariant,
              ),
            },),
          ],
        },),
      },),
    },),
  },);
},);
var css7 = [
  '@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }',
  '.framer-ZLRxz.framer-1378plg, .framer-ZLRxz .framer-1378plg { display: block; }',
  '.framer-ZLRxz.framer-1usrhsu { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 32px; height: min-content; justify-content: center; overflow: hidden; padding: 140px 0px 140px 0px; position: relative; width: 1140px; }',
  '.framer-ZLRxz .framer-1a199pe-container { flex: none; height: auto; position: relative; width: 56%; }',
  '.framer-ZLRxz .framer-10e2ga6 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }',
  '.framer-ZLRxz .framer-yhwl67-container { flex: none; height: auto; position: relative; width: auto; }',
  '.framer-ZLRxz .framer-15iblgi { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 243px; justify-content: center; left: 0px; overflow: hidden; padding: 0px; position: absolute; top: 18px; width: 16%; z-index: 1; }',
  '.framer-ZLRxz .framer-qtiat9 { align-content: center; align-items: center; bottom: 57px; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 243px; justify-content: center; overflow: hidden; padding: 0px; position: absolute; right: 0px; width: 16%; z-index: 1; }',
  '@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-ZLRxz.framer-1usrhsu, .framer-ZLRxz .framer-15iblgi, .framer-ZLRxz .framer-qtiat9 { gap: 0px; } .framer-ZLRxz.framer-1usrhsu > * { margin: 0px; margin-bottom: calc(32px / 2); margin-top: calc(32px / 2); } .framer-ZLRxz.framer-1usrhsu > :first-child { margin-top: 0px; } .framer-ZLRxz.framer-1usrhsu > :last-child { margin-bottom: 0px; } .framer-ZLRxz .framer-15iblgi > *, .framer-ZLRxz .framer-qtiat9 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-ZLRxz .framer-15iblgi > :first-child, .framer-ZLRxz .framer-qtiat9 > :first-child { margin-left: 0px; } .framer-ZLRxz .framer-15iblgi > :last-child, .framer-ZLRxz .framer-qtiat9 > :last-child { margin-right: 0px; } }',
  '.framer-ZLRxz.framer-v-sitpyi.framer-1usrhsu { padding: 100px 0px 100px 0px; width: 992px; }',
  '.framer-ZLRxz.framer-v-sitpyi .framer-1a199pe-container { width: 53%; }',
  '.framer-ZLRxz.framer-v-sitpyi .framer-15iblgi { top: 0px; width: 16%; }',
  '.framer-ZLRxz.framer-v-sitpyi .framer-qtiat9 { bottom: 0px; width: 16%; }',
  '.framer-ZLRxz.framer-v-1ybeht9.framer-1usrhsu { padding: 80px 0px 80px 0px; width: 708px; }',
  '.framer-ZLRxz.framer-v-1ybeht9 .framer-1a199pe-container { width: 57%; }',
  '.framer-ZLRxz.framer-v-1ybeht9 .framer-15iblgi { height: 170px; top: 0px; width: 18%; }',
  '.framer-ZLRxz.framer-v-1ybeht9 .framer-qtiat9 { bottom: 0px; height: 170px; width: 18%; }',
  '.framer-ZLRxz.framer-v-7efl8s.framer-1usrhsu { padding: 60px 0px 60px 0px; width: 350px; }',
  '.framer-ZLRxz.framer-v-7efl8s .framer-1a199pe-container { width: 100%; }',
  '.framer-ZLRxz.framer-v-7efl8s .framer-15iblgi { height: 60px; top: 20px; width: 18%; }',
  '.framer-ZLRxz.framer-v-7efl8s .framer-qtiat9 { bottom: 0px; height: 60px; width: 18%; }',
  ...css4,
];
var FramerFF233TYmj = withCSS2(Component2, css7, 'framer-ZLRxz',);
var stdin_default3 = FramerFF233TYmj;
FramerFF233TYmj.displayName = 'PricingBanner';
FramerFF233TYmj.defaultProps = { height: 615, width: 1140, };
addPropertyControls2(FramerFF233TYmj, {
  variant: {
    options: ['FZPxxJjOq', 'OStzbM18C', 'RuodeXOmu', 'yVtgPmqxI',],
    optionTitles: ['Desktop', 'Laptop', 'Tablet', 'Mobile',],
    title: 'Variant',
    type: ControlType2.Enum,
  },
  Dm4Edjd64: { title: 'crispUrl', type: ControlType2.Link, },
},);
addFonts2(FramerFF233TYmj, [
  {
    explicitInter: true,
    fonts: [{
      family: 'Inter',
      source: 'framer',
      style: 'normal',
      unicodeRange: 'U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F',
      url: 'https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2',
      weight: '400',
    }, {
      family: 'Inter',
      source: 'framer',
      style: 'normal',
      unicodeRange: 'U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116',
      url: 'https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2',
      weight: '400',
    }, {
      family: 'Inter',
      source: 'framer',
      style: 'normal',
      unicodeRange: 'U+1F00-1FFF',
      url: 'https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2',
      weight: '400',
    }, {
      family: 'Inter',
      source: 'framer',
      style: 'normal',
      unicodeRange: 'U+0370-03FF',
      url: 'https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2',
      weight: '400',
    }, {
      family: 'Inter',
      source: 'framer',
      style: 'normal',
      unicodeRange: 'U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF',
      url: 'https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2',
      weight: '400',
    }, {
      family: 'Inter',
      source: 'framer',
      style: 'normal',
      unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
      url: 'https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2',
      weight: '400',
    }, {
      family: 'Inter',
      source: 'framer',
      style: 'normal',
      unicodeRange: 'U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB',
      url: 'https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2',
      weight: '400',
    },],
  },
  ...SectionTitleFonts,
  ...ButtonFonts,
  ...getFontsFromSharedStyle2(fonts4,),
], { supportsExplicitInterCodegen: true, },);

// virtual:pricing-banner
import { WithFramerBreakpoints, } from 'unframer';
import { jsx, } from 'react/jsx-runtime';
stdin_default3.Responsive = (props,) => {
  return /* @__PURE__ */ jsx(WithFramerBreakpoints, { Component: stdin_default3, ...props, },);
};
var pricing_banner_default = stdin_default3;
export { pricing_banner_default as default, };
