/* eslint-disable */
import { className, css, fonts, Icon, } from './chunk-UV4LTKKZ.js';

// https :https://framerusercontent.com/modules/ncTpzEiw3qNOep3VTZcG/3aEAyRlZV6QPh0MaDd3X/IV_7SD635.js
import { jsx as _jsx, jsxs as _jsxs, } from 'react/jsx-runtime';
import {
  addFonts,
  addPropertyControls,
  ComponentViewportProvider,
  ControlType,
  cx,
  getFonts,
  Link,
  RichText,
  useActiveVariantCallback,
  useComponentViewport,
  useLocaleInfo,
  useVariantState,
  withCSS,
} from 'unframer';
import { LayoutGroup, motion, MotionConfigContext, } from 'unframer';
import * as React from 'react';
var FeatherFonts = getFonts(Icon,);
var enabledGestures = { qoShHVBll: { hover: true, }, };
var cycleOrder = ['qoShHVBll',];
var serializationHash = 'framer-PaIEH';
var variantClassNames = { qoShHVBll: 'framer-v-1bo1b3n', };
function addPropertyOverrides(overrides, ...variants) {
  const nextOverrides = {};
  variants === null || variants === void 0
    ? void 0
    : variants.forEach((variant,) => variant && Object.assign(nextOverrides, overrides[variant],));
  return nextOverrides;
}
var transitions = { default: { damping: 60, delay: 0, mass: 1, stiffness: 500, type: 'spring', }, };
var Transition = ({ value, children, },) => {
  const config = React.useContext(MotionConfigContext,);
  const transition = value !== null && value !== void 0 ? value : config.transition;
  const contextValue = React.useMemo(() => ({ ...config, transition, }), [JSON.stringify(transition,),],);
  return /* @__PURE__ */ _jsx(MotionConfigContext.Provider, { value: contextValue, children, },);
};
var Variants = motion(React.Fragment,);
var getProps = ({ height, hover, iconVisible, id, link, tap, title, width, ...props },) => {
  var _ref;
  return {
    ...props,
    cRqRWoih6: link !== null && link !== void 0 ? link : props.cRqRWoih6,
    Oc0fzh3x0: iconVisible !== null && iconVisible !== void 0 ? iconVisible : props.Oc0fzh3x0,
    okFfDCMAr: (_ref = title !== null && title !== void 0 ? title : props.okFfDCMAr) !== null && _ref !== void 0 ? _ref : 'Home',
    oxH5zj9aM: tap !== null && tap !== void 0 ? tap : props.oxH5zj9aM,
    Usq46WHfY: hover !== null && hover !== void 0 ? hover : props.Usq46WHfY,
  };
};
var createLayoutDependency = (props, variants,) => variants.join('-',) + props.layoutDependency;
var Component = /* @__PURE__ */ React.forwardRef(function (props, ref,) {
  const { activeLocale, setLocale, } = useLocaleInfo();
  const { style, className: className2, layoutId, variant, okFfDCMAr, cRqRWoih6, Oc0fzh3x0, oxH5zj9aM, Usq46WHfY, ...restProps } = getProps(
    props,
  );
  const { baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants, } = useVariantState({
    cycleOrder,
    defaultVariant: 'qoShHVBll',
    enabledGestures,
    transitions,
    variant,
    variantClassNames,
  },);
  const layoutDependency = createLayoutDependency(props, variants,);
  const { activeVariantCallback, delay, } = useActiveVariantCallback(baseVariant,);
  const onTap1k44oqp = activeVariantCallback(async (...args) => {
    setGestureState({ isPressed: false, },);
    if (oxH5zj9aM) {
      const res = await oxH5zj9aM(...args,);
      if (res === false) return false;
    }
  },);
  const onMouseEnter185usnc = activeVariantCallback(async (...args) => {
    if (Usq46WHfY) {
      const res = await Usq46WHfY(...args,);
      if (res === false) return false;
    }
  },);
  const ref1 = React.useRef(null,);
  const defaultLayoutId = React.useId();
  const sharedStyleClassNames = [className,];
  const componentViewport = useComponentViewport();
  return /* @__PURE__ */ _jsx(LayoutGroup, {
    id: layoutId !== null && layoutId !== void 0 ? layoutId : defaultLayoutId,
    children: /* @__PURE__ */ _jsx(Variants, {
      animate: variants,
      initial: false,
      children: /* @__PURE__ */ _jsx(Transition, {
        value: transition,
        children: /* @__PURE__ */ _jsx(Link, {
          href: cRqRWoih6,
          openInNewTab: false,
          smoothScroll: true,
          children: /* @__PURE__ */ _jsxs(motion.a, {
            ...restProps,
            className: `${cx(serializationHash, ...sharedStyleClassNames, 'framer-1bo1b3n', className2, classNames,)} framer-13u7j1e`,
            'data-framer-name': 'Primary Navitem',
            'data-highlight': true,
            layoutDependency,
            layoutId: 'qoShHVBll',
            onHoverEnd: () => setGestureState({ isHovered: false, },),
            onHoverStart: () => setGestureState({ isHovered: true, },),
            onMouseEnter: onMouseEnter185usnc,
            onTap: onTap1k44oqp,
            onTapCancel: () => setGestureState({ isPressed: false, },),
            onTapStart: () => setGestureState({ isPressed: true, },),
            ref: ref !== null && ref !== void 0 ? ref : ref1,
            style: { ...style, },
            ...addPropertyOverrides({ 'qoShHVBll-hover': { 'data-framer-name': void 0, }, }, baseVariant, gestureVariant,),
            children: [
              /* @__PURE__ */ _jsx(RichText, {
                __fromCanvasComponent: true,
                children: /* @__PURE__ */ _jsx(React.Fragment, {
                  children: /* @__PURE__ */ _jsx(motion.p, {
                    className: 'framer-styles-preset-17ww93c',
                    'data-styles-preset': 'uMdppUE31',
                    style: {
                      '--framer-text-color':
                        'var(--extracted-r6o4lv, var(--token-b50174c0-f355-49d1-a882-48c9e51b5466, rgb(255, 255, 255)))',
                    },
                    children: 'Home',
                  },),
                },),
                className: 'framer-1qpi1eb',
                layoutDependency,
                layoutId: 'nFiAiLydr',
                style: {
                  '--extracted-r6o4lv': 'var(--token-b50174c0-f355-49d1-a882-48c9e51b5466, rgb(255, 255, 255))',
                  '--framer-paragraph-spacing': '0px',
                },
                text: okFfDCMAr,
                variants: { 'qoShHVBll-hover': { '--extracted-r6o4lv': 'rgba(255, 255, 255, 0.6)', }, },
                verticalAlignment: 'top',
                withExternalLayout: true,
                ...addPropertyOverrides(
                  {
                    'qoShHVBll-hover': {
                      children: /* @__PURE__ */ _jsx(React.Fragment, {
                        children: /* @__PURE__ */ _jsx(motion.p, {
                          className: 'framer-styles-preset-17ww93c',
                          'data-styles-preset': 'uMdppUE31',
                          style: { '--framer-text-color': 'var(--extracted-r6o4lv, rgba(255, 255, 255, 0.6))', },
                          children: 'Home',
                        },),
                      },),
                    },
                  },
                  baseVariant,
                  gestureVariant,
                ),
              },),
              Oc0fzh3x0 && /* @__PURE__ */ _jsx(ComponentViewportProvider, {
                children: /* @__PURE__ */ _jsx(motion.div, {
                  className: 'framer-10aaz4-container',
                  layoutDependency,
                  layoutId: 'GP7VlGddj-container',
                  style: { rotate: 0, },
                  variants: { 'qoShHVBll-hover': { rotate: 180, }, },
                  children: /* @__PURE__ */ _jsx(Icon, {
                    color: 'var(--token-b50174c0-f355-49d1-a882-48c9e51b5466, rgb(255, 255, 255))',
                    height: '100%',
                    iconSearch: 'Home',
                    iconSelection: 'plus',
                    id: 'GP7VlGddj',
                    layoutId: 'GP7VlGddj',
                    mirrored: false,
                    selectByList: true,
                    style: { height: '100%', width: '100%', },
                    width: '100%',
                    ...addPropertyOverrides({ 'qoShHVBll-hover': { color: 'rgba(255, 255, 255, 0.6)', }, }, baseVariant, gestureVariant,),
                  },),
                },),
              },),
            ],
          },),
        },),
      },),
    },),
  },);
},);
var css2 = [
  '.framer-PaIEH[data-border="true"]::after, .framer-PaIEH [data-border="true"]::after { content: ""; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }',
  '@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }',
  '.framer-PaIEH.framer-13u7j1e, .framer-PaIEH .framer-13u7j1e { display: block; }',
  '.framer-PaIEH.framer-1bo1b3n { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: min-content; }',
  '.framer-PaIEH .framer-1qpi1eb { cursor: pointer; flex: none; height: auto; overflow: visible; position: relative; white-space: pre; width: auto; }',
  '.framer-PaIEH .framer-10aaz4-container { flex: none; height: 16px; position: relative; width: 16px; }',
  '@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-PaIEH.framer-1bo1b3n { gap: 0px; } .framer-PaIEH.framer-1bo1b3n > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-PaIEH.framer-1bo1b3n > :first-child { margin-left: 0px; } .framer-PaIEH.framer-1bo1b3n > :last-child { margin-right: 0px; } }',
  ...css,
];
var FramerIV_7SD635 = withCSS(Component, css2, 'framer-PaIEH',);
var stdin_default = FramerIV_7SD635;
FramerIV_7SD635.displayName = 'Nav-item';
FramerIV_7SD635.defaultProps = { height: 27, width: 44, };
addPropertyControls(FramerIV_7SD635, {
  okFfDCMAr: { defaultValue: 'Home', displayTextArea: false, title: 'Title', type: ControlType.String, },
  cRqRWoih6: { title: 'Link', type: ControlType.Link, },
  Oc0fzh3x0: { defaultValue: false, title: 'Icon Visible', type: ControlType.Boolean, },
  oxH5zj9aM: { title: 'Tap', type: ControlType.EventHandler, },
  Usq46WHfY: { title: 'Hover', type: ControlType.EventHandler, },
},);
addFonts(FramerIV_7SD635, [...FeatherFonts, ...fonts,],);

export { stdin_default, };
